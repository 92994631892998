import React from "react";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LogoProps {}

export const Logo: React.FC<LogoProps> = ({}) => {
  return (
    <>
      <svg
        className="w-36 mx-auto"
        width="210px"
        height="50px"
        viewBox="0 0 210 50"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <polygon id="path-1" points="0 0 23.7195807 0 23.7195807 50 0 50"></polygon>
        </defs>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Group-22">
            <g id="Group-3">
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1"></use>
              </mask>
              <g id="Clip-2"></g>
              <path
                d="M4.70973476,49.5139879 C1.16049267,48.405426 0.105498038,43.9505301 0.023585853,38.045513 C-0.153533164,25.3075907 0.723055869,10.1249818 0.783525074,2.12242975 C0.805825773,-0.871590698 17.1136411,0.178467335 20.0354616,0.178467335 C22.1128575,0.178467335 20.0354616,3.58631647 19.1305679,3.61298734 C15.2635408,3.72526311 8.68783646,4.13177882 8.68783646,4.13177882 L8.45753885,22.7566508 C8.45753885,22.7566508 16.8271629,22.087298 20.5269347,23.134775 C22.2097798,23.6114093 21.045426,25.4048103 20.2018591,25.4994489 C17.8431313,25.7652973 8.55188797,26.2561273 8.55188797,26.2561273 C8.55188797,26.2561273 8.35632799,28.5657388 8.49870937,32.920834 C8.63465787,37.0750374 9.15400685,46.4855534 9.15400685,46.4855534 C9.15400685,46.4855534 18.7896245,46.2463759 23.0464849,46.5857843 C24.1713837,47.4796886 23.9333666,49.3737507 21.9756225,49.5376475 C17.5622283,49.906738 7.75678226,50.1794692 5.82605631,49.8568376 C5.53829152,49.808658 4.70973476,49.5139879 4.70973476,49.5139879"
                id="Fill-1"
                fill="#000000"
                mask="url(#mask-2)"
              ></path>
            </g>
            <path
              d="M32.3403443,47.586114 C29.8602492,48.8770703 27.5083831,49.8991669 26.7034137,47.4656649 C24.4900692,40.7755779 26.6047759,15.017108 27.1489988,11.5219333 C27.380583,10.0335265 27.6533377,8.48102369 28.7743767,7.76822313 C32.9544713,5.11102999 33.9734417,6.74741702 34.1325486,10.2980844 C34.4023013,18.510562 33.8795214,26.260171 33.080556,35.2478246 C33.0646882,35.4267775 35.3625179,29.4890672 36.5410241,27.6646935 C39.1660738,23.5999666 42.1822434,23.4020859 44.031486,23.4752157 C47.0656677,23.588782 48.2227309,24.7536969 47.7295424,28.7160421 C47.4722266,30.7826044 46.6672571,39.3035178 46.9194266,41.8114401 C47.092257,43.5342924 48.8784573,42.5483305 50.7084012,40.4357393 C53.4436678,37.2786824 53.9402872,33.5099162 55.6295652,30.580422 C56.3556245,29.3204384 56.018112,32.5007248 55.8144037,33.178251 C54.881634,40.5295176 51.0309036,45.7901319 47.361152,47.2729464 C45.1598156,48.1621188 43.8127676,48.4929237 41.8211436,46.295588 C40.1910482,44.4970248 41.1572689,36.573625 41.4994989,32.4258743 C42.0617338,25.610176 39.2059577,26.0678826 36.3274521,33.1619043 C34.7882749,36.9556207 34.9662517,36.6157822 33.5248545,40.3910011 C32.7966509,42.2975383 33.5420089,46.9606391 32.3403443,47.586114"
              id="Fill-4"
              fill="#000000"
            ></path>
            <path
              d="M57.1673271,48.5421357 C55.6423023,48.101206 55.43259,46.6472133 55.2057232,44.9174782 C54.6958092,41.0329948 54.2416468,35.374038 54.299114,30.8395597 C54.3497195,26.8458117 54.4140484,26.1175248 54.1575904,23.0383297 C54.0606681,21.8704036 59.195833,21.594231 60.5415944,22.6279424 C61.886927,23.6616537 61.5687132,30.906667 61.5687132,30.906667 C61.5687132,30.906667 63.1216138,27.0071275 66.1583687,24.1408691 C67.2069304,23.1514658 69.6055422,21.8936331 70.9182814,22.1990576 C71.5049614,22.3358533 72.0684829,23.8337239 71.4916667,24.0574151 C69.3409358,24.8919553 68.158141,26.2379739 66.9611939,27.6751898 C60.814778,35.0548479 64.44679,43.3593831 62.3153578,46.9982364 C61.6025931,48.2156326 59.8073868,49.305697 57.1673271,48.5421357"
              id="Fill-6"
              fill="#000000"
            ></path>
            <path
              d="M83.0947207,29.7178774 C83.8246397,28.6415787 85.4603103,26.7918247 85.2145737,24.9188412 C85.1232266,24.2232477 84.7689886,23.3977412 83.9292815,23.4764633 C82.2202759,23.6300359 80.3791817,31.9646833 80.3791817,31.9646833 C80.3791817,31.9646833 81.4011541,31.8967156 83.0947207,29.7178774 M74.9609694,46.1836994 C72.5250468,44.6148499 71.1166719,42.1155311 70.6603653,39.2406692 C69.8176562,33.9305847 72.1347846,27.1097243 75.7144757,23.299231 C78.768385,20.0488261 82.7404826,18.988874 86.4531203,22.9258388 C88.3533972,25.1825387 87.150446,27.1282218 85.9046088,29.6159259 C84.7269603,31.9672643 82.161951,34.0617881 80.1523149,35.3041345 C76.7698992,47.2944121 80.9782985,47.5335896 85.491617,43.4684325 C89.0022617,40.3066437 92.422846,31.5259044 92.8752928,26.6606212 C95.8314221,25.3227759 94.328698,33.487504 93.5962058,34.6937157 C92.3349297,39.8906641 88.6814748,46.1970348 84.0566528,47.907412 C81.3960078,48.8916532 78.5046363,48.4662098 74.9609694,46.1836994"
              id="Fill-8"
              fill="#000000"
            ></path>
            <path
              d="M93.5598814,46.5847088 C93.1310218,45.8284606 93.1267332,44.7947492 93.0765567,43.9593487 C92.6738575,37.2249535 93.0992862,32.8638358 92.7819301,27.2517682 C92.7459059,26.6155388 92.6974448,23.9794243 92.9401793,23.3922349 C93.2905576,22.5439291 94.676203,22.2828127 95.3392199,22.0913847 C100.63049,20.5629716 100.271105,24.9787216 100.179758,30.4058139 C100.180616,30.8544867 100.121005,32.1239342 100.121005,32.1239342 C100.121005,32.1239342 101.362124,29.2503629 102.033718,28.2863399 C105.263031,23.652491 107.449786,23.2670539 110.300416,24.4943442 C111.710935,25.1021819 112.51033,26.9902216 112.843554,29.4912611 C113.04812,31.0248362 113.109447,34.3126664 113.124457,36.2609306 C113.145471,38.965443 113.14204,39.3427068 113.686263,40.4796602 C113.931142,40.9915689 114.255788,42.0317329 114.813306,42.1070136 C115.534219,42.2042332 116.213532,41.5021871 116.678845,40.9915689 C119.440272,38.092187 120.289843,32.6315411 121.846603,29.4878197 C124.734973,28.9909671 122.093626,35.0646129 121.557552,36.7754203 C120.175337,41.1894496 117.974859,44.3921051 115.386262,45.8830928 C112.591384,47.492809 109.227838,47.2557824 107.501678,44.3766188 C106.533742,42.7626008 106.297012,36.0652008 106.456976,33.435969 C107.031219,26.4546533 104.399737,30.553364 101.555969,35.3751995 C99.3850815,39.0557798 100.194768,47.1632947 98.9583661,47.4115059 C98.0071555,47.6020735 94.5411122,48.3157344 93.5598814,46.5847088"
              id="Fill-10"
              fill="#000000"
            ></path>
            <path
              d="M134.145696,29.8523072 C135.816105,27.643787 138.501195,23.3003065 135.471301,24.037627 C134.313809,24.3193919 130.332277,27.9754522 129.57148,30.5074643 C129.470269,30.8451519 129.305158,32.6338211 129.406798,32.9723691 C129.509295,33.3139283 133.043527,31.3093111 134.145696,29.8523072 M123.9041,49.6304794 C122.961467,49.7272688 121.11008,49.727699 120.872492,48.5236382 C119.728294,41.2588369 122.16293,15.4384217 122.449837,12.1441389 C122.605942,10.3567603 127.265931,9.2641149 128.192696,10.4548403 C129.921858,12.676696 129.038836,20.9752087 128.819689,25.5914204 C128.844992,25.0545615 132.276726,21.2315932 135.236286,20.2365976 C137.524681,19.4670139 140.542995,19.8533114 140.462798,23.9335246 C140.335427,30.4158369 133.962144,35.3826416 133.962144,35.3826416 C133.962144,35.3826416 134.951524,37.1872272 136.570469,39.8035536 C137.256215,40.9112552 138.664161,42.1191876 140.063959,42.0365939 C141.441027,41.9552907 143.049679,39.1212955 144.293801,36.5006673 C145.741202,33.4528749 147.015773,27.4015983 147.947256,25.5621684 C148.227302,25.0098233 149.199526,25.8615705 149.602225,26.9077569 C148.628285,33.0248504 145.536636,42.4422492 142.379372,45.1734326 C140.585023,46.7255052 138.911184,47.7519036 136.884823,47.3522707 C134.85889,46.9526378 132.917014,45.4513258 131.45932,42.5953917 C130.611893,40.9344846 129.198801,38.9715945 129.198801,38.9715945 C129.198801,38.9715945 129.179073,37.2719717 128.382252,42.8801678 C128.017721,45.4448732 127.632606,47.5557437 126.248247,49.0325356 C125.728469,49.587892 124.790553,49.5397124 123.9041,49.6304794"
              id="Fill-12"
              fill="#000000"
            ></path>
            <path
              d="M149.375144,47.159294 C148.107435,45.5629133 147.9702,43.4322548 147.652415,41.5196952 C146.847875,36.6759207 147.45514,31.6273828 147.703449,26.4132274 C147.821386,23.9397191 152.227918,24.0274749 152.463791,25.3154199 C153.194568,29.5070485 152.816743,37.9836538 153.021309,43.5974421 C153.039321,44.0934343 152.968559,45.714335 153.87817,45.7517603 C154.092171,45.760794 154.521031,45.2673829 154.788639,44.9735731 C158.801907,40.5651361 157.86056,30.7648812 159.663057,26.4089256 C159.935383,25.7511875 160.182835,26.0764001 160.31621,26.905348 C160.718052,29.3964934 160.52335,34.6691527 160.180262,36.7163572 C159.34999,41.6702566 157.509324,47.4346063 154.208821,48.4726194 C152.826606,48.9075267 150.840986,49.0047463 149.375144,47.159294"
              id="Fill-14"
              fill="#000000"
            ></path>
            <path
              d="M160.732333,47.4305196 C160.249866,46.4630552 160.146511,45.3695495 160.029003,44.4816676 C159.491213,40.4143596 159.852742,37.7382388 159.852742,32.6625998 C159.852742,30.1753259 159.756677,28.9523374 159.625446,26.826841 C159.524664,25.202929 162.164724,24.6626288 163.131802,24.4647481 C167.398956,23.5914921 166.624864,25.5569633 166.467472,30.511723 C166.441312,31.3454029 166.364975,32.8759668 166.361115,34.4138436 C166.359829,34.9261825 168.163183,30.906624 168.306422,30.6549714 C170.976931,25.9686411 174.245699,25.3091823 176.80642,25.6146068 C178.578897,25.8258229 179.948674,27.2617482 180.360379,30.3516978 C180.653719,32.5559163 180.466737,37.9782766 180.586388,40.5227638 C180.686742,41.8464134 180.468023,42.9549752 181.508008,42.5411466 C182.55271,42.1449551 183.826423,39.3806483 184.168653,38.6674175 C186.418879,33.9767855 186.586134,33.3048516 188.101724,30.2411427 C190.189413,29.7382677 189.359998,34.1484255 187.403541,38.0948541 C186.09509,40.7339799 184.468426,43.6010986 182.707528,45.262866 C181.642241,46.2677556 181.211666,46.6893275 177.768352,46.886778 C175.784447,47.0003443 174.636819,45.3377165 174.347768,43.0199317 C173.930916,39.6796201 174.525744,29.6900881 172.672642,30.3529883 C171.036114,30.938457 168.429934,36.9544593 166.715782,42.6340644 C166.137679,44.5487749 165.957987,46.4768209 165.552715,47.6339925 C165.277387,48.4199229 163.22658,48.4629405 162.409603,48.4143306 C161.497419,48.3596984 161.039825,48.0473911 160.732333,47.4305196"
              id="Fill-16"
              fill="#000000"
            ></path>
            <path
              d="M194.767489,42.5138734 C195.360173,41.6062035 199.165015,32.883968 200.162114,29.3449154 C201.177225,25.7413365 197.43328,26.6778282 196.437468,28.8708622 C195.676671,29.7651967 192.754422,37.2080907 192.259947,41.2061404 C192.075966,42.6928264 192.744129,43.5364003 193.239891,43.6301785 C193.566682,43.6916936 194.128917,43.4920922 194.767489,42.5138734 M205.561028,49.6509127 C204.60853,49.3317226 203.508077,48.9415535 203.046624,48.591821 C202.232219,47.9740892 202.19448,47.5830598 201.929444,46.3850214 C200.8856,41.6647073 202.138728,33.5735391 202.138728,33.5735391 C202.138728,33.5735391 198.748593,47.0840564 193.239462,47.1946114 C190.975941,47.24021 188.594484,46.3592109 187.315624,44.1537019 C186.794131,43.2542052 186.581846,41.5051122 186.439035,40.4920493 C185.416634,35.9115421 187.335781,30.3979847 190.276042,26.9410955 C192.634341,24.1686154 194.800511,22.1622776 199.80616,23.1946984 C200.997532,23.4403285 202.353587,24.7889283 202.353587,24.7889283 C202.353587,24.7889283 202.30727,20.0385018 202.397759,17.3107599 C202.607471,10.9575002 202.50283,7.32811073 204.201543,6.93450028 C206.305957,6.35376356 208.50858,5.56826337 209.092686,6.68757963 C210.332091,9.0617174 210.021167,15.6563055 209.774573,20.3546807 C209.533125,24.9618587 209.231637,45.5152069 208.628231,49.3484994 C208.522732,50.0169919 206.542687,49.9799968 205.561028,49.6509127"
              id="Fill-18"
              fill="#000000"
            ></path>
            <path
              d="M147.995546,16.1265732 C148.443275,13.3347352 150.229904,12.7127016 151.599682,12.9101521 C152.896553,13.0964181 153.70624,14.2544501 154.025741,15.3449446 C154.441306,16.7623724 154.303213,18.6753622 153.28124,19.7378953 C152.75117,20.2885198 150.70551,21.1028417 149.268401,19.864797 C148.044436,18.8250632 147.465046,18.4813531 147.995546,16.1265732"
              id="Fill-20"
              fill="#000000"
            ></path>
          </g>
        </g>
      </svg>
    </>
  );
};
